import { TrajectoryType } from 'app/common/models/cm/trajectorytype.enum';
import { IUiConfiguration } from 'environments/iui.config';

export const febelfinAcademyUiConfiguration: IUiConfiguration = {
  gdprDialog: {
    showAcceptNewsletter: true
  },
  myCertificates: {
    showAttendanceCertificates: true,
    showExtraTrainingCertificates: true
  },
  user360: {
    showTrainingResults: true,
    showAccreditations: true
  },
  locations: {
    showFacilities: true,
    showCategories: true,
    showO365Mail: true
  },
  courseManagement: {
    showSelfManagementToggle: true
  },
  courseCatalog: {
    defaultType: TrajectoryType.Trajectory,
    showTypeFilter: true,
    showCategoryFilter: true,
    showCertificationTypeFilter: true,
    showDifficultyLevelFilter: true,
    showAccreditationTypeFilter: true,
    showLearningTypeFilter: true
  }
};

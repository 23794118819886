import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import * as _ from 'lodash';

import { AppState } from '../../redux/app.state';
import { SetCurrentCompanyAction } from '../../redux/auth/auth.actions';
import { authSelectors } from '../../redux/auth/auth.selector';
import { INamedResource } from './../models/inamedresource.model';
import { AuthUserModel } from './authuser.model';

@Injectable()
export class CurrentUserService {
  private _currentUser: AuthUserModel;
  private _currentCompany: number;

  public get isAuthenticated(): boolean {
    return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
  }

  public get permissions(): string[] {
    return this._currentUser ? this._currentUser.permissions || [] : [];
  }

  public get roles(): string[] {
    return this._currentUser ? this._currentUser.roles || [] : [];
  }

  public get userId(): number {
    return this._currentUser ? this._currentUser.id : null;
  }

  public get isLinkedWithItsme(): boolean {
    return this._currentUser ? this._currentUser.isLinkedWithItsme : null;
  }

  public get fullName(): string {
    return this._currentUser ? `${this._currentUser.firstName} ${this._currentUser.lastName}` : null;
  }

  public get email(): string {
    return this.isAuthenticated && this._currentUser ? this._currentUser.email : null;
  }

  public profilePicture: SafeResourceUrl = undefined;

  public get initials(): string {
    return this.isAuthenticated ? `${this._currentUser.firstName.charAt(0)}${this._currentUser.lastName.charAt(0)}`.toUpperCase() : 'X';
  }

  public get userColors(): { color: string; contrast: string } {
    if (!this.isAuthenticated) {
      return { color: '#000', contrast: '#fff' };
    }
    const color = String.stringToHexColor(this.fullName);
    const contrast = parseInt(color.replace('#', ''), 16) > 0xffffff / 2 ? '#000' : '#fff';

    return { color: color, contrast: contrast };
  }

  public get currentCompanies(): INamedResource[] {
    return this._currentUser.currentCompanies;
  }

  public get currentCompany(): number {
    return this._currentCompany;
  }

  constructor(private appState: Store<AppState>, private oauthService: OAuthService, public sanitizer: DomSanitizer) {
    appState.select(authSelectors.currentCompanyId).subscribe(c => (this._currentCompany = c));
  }

  public setUser(user: AuthUserModel) {
    this._currentUser = user;
    this.profilePicture =
      this.isAuthenticated && this._currentUser && this._currentUser.profilePicturePublicUrl
        ? this.sanitizer.bypassSecurityTrustResourceUrl(this._currentUser.profilePicturePublicUrl)
        : undefined;
  }

  public addPermissions(...permissions: string[]) {
    this._currentUser.permissions = [...permissions];
  }

  public setCurrentCompany(company: INamedResource) {
    this._currentCompany = company.id;
    this.appState.dispatch(new SetCurrentCompanyAction(company.id));
  }

  public clearRolesAndPermissions() {
    this._currentUser.roles = [];
    this._currentUser.permissions = [];
  }

  public computeHighestRole(): 'AFA' | 'SU' | 'OV' | 'IA' | null {
    if (_.some(this._currentUser.roles, r => r.toLocaleLowerCase() === 'afa')) {
      return 'AFA';
    }
    if (_.some(this._currentUser.roles, r => r.toLocaleLowerCase() === 'su')) {
      return 'SU';
    }
    if (_.some(this._currentUser.roles, r => r.toLocaleLowerCase() === 'ia')) {
      return 'IA';
    }
    if (_.some(this._currentUser.roles, r => r.toLocaleLowerCase() === 'ov')) {
      return 'OV';
    }
    return null;
  }

  public isSuperUserOrAdmin(): boolean {
    return this.roles.some(r => r === 'AFA' || r === 'SU');
  }
}

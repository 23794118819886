import { TrajectoryType } from 'app/common/models/cm/trajectorytype.enum';
import { IUiConfiguration } from 'environments/iui.config';

export const certassurUiConfiguration: IUiConfiguration = {
  gdprDialog: {
    showAcceptNewsletter: false
  },
  myCertificates: {
    showAttendanceCertificates: false,
    showExtraTrainingCertificates: false
  },
  user360: {
    showTrainingResults: false,
    showAccreditations: false
  },
  locations: {
    showFacilities: false,
    showCategories: false,
    showO365Mail: false
  },
  courseManagement: {
    showSelfManagementToggle: false
  },
  courseCatalog: {
    defaultType: TrajectoryType.Program,
    showTypeFilter: false,
    showCategoryFilter: false,
    showCertificationTypeFilter: false,
    showDifficultyLevelFilter: false,
    showAccreditationTypeFilter: false,
    showLearningTypeFilter: false
  }
};
